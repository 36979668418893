<template>
    <div class="poster_add">
        <!-- 页头部分 页面标题-->
        <div class="Page_header">
            <h1 class="header_re" @click="handleBack"><i class="el-icon-arrow-left" style="margin-right:16px"></i>广告管理
            </h1>
            <h1 class="new_build">{{title}}</h1>
        </div>
        <!-- 内容部分 -->
        <div class="basic_box">
            <h3>基本信息</h3>
            <div class="content_part project_name">
                <span>推广项目名称</span>
                <el-input v-model="project_name" clearable placeholder="请输入项目名称" size="mini"></el-input>
            </div>
        </div>
        <div class="basic_box">
            <h3>广告形式</h3>
            <div class="content_part shape_box">
                <span>广告展示形式</span>
                <div class="shap_img">图片</div>
            </div>
        </div>
        <div class="basic_box">
            <h3>广告版位</h3>
            <div class="content_part poster_wei">
                <span>广告展示频道</span>
                <div class="advert_box">
                    <div class="guan_weizhi" @click="handlePost(item.id)" v-for="(item,index) in poster_list"
                        :key="index" :style="{'background':item.background,'borderColor':item.borderColor}">
                        {{item.ad_source}}</div>
                    <el-select v-model="wei_post" placeholder="分类" size="mini" clearable style="margin-top:16px"
                        @change="position_Fn" v-if="sel_show">
                        <el-option v-for="item in sel_data" :key="item.id" :label="item.ad_position" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div class="advertise" v-if="ad_show">
            <div class="ad_wei" v-for="(item,index) in ad_list" :key="index">
                <div class="ad_zhi" v-if="ad_son_show" @click="handleAdver(item.name)"
                    :style="{'background':item.background,'borderColor':item.borderColor}">{{item.name}}</div>
                <div class="ad_post" v-else>
                    <div class="ad_guan" v-for="(item,index) in po_list" :key="index" @click="img_poition(item.uid)"
                        :style="{'background':item.background,'borderColor':item.borderColor,'width':item.width}">
                        {{item.img_name}}</div>
                </div>
            </div>
        </div>
        <div class="basic_box">
            <h3>排期与出价</h3>
            <div>
                <div class="content_part Launch_box">
                    <span>投放时长</span>
                    <div class="duration">
                        <div class="during_box" @click="Delivery_time(index)" v-for="(item,index) in delivery"
                            :key="index" :style="{'background':item.background,'borderColor':item.borderColor}">
                            {{item.name}}</div>
                    </div>
                </div>
                <div class="content_part Internet_box" v-if="Double_short">
                    <span>投放日期</span>
                    <div class="Single_date" v-if="Single_long">
                        <el-date-picker v-model="date_time" type="date" placeholder="选择日期" size="mini"
                            format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
                    </div>
                    <div class="Double_date" v-else>
                        <el-date-picker v-model="start_date" type="date" placeholder="开始日期" size="mini"
                            format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                        </el-date-picker>
                        <span>-</span>
                        <el-date-picker v-model="end_date" type="date" placeholder="结束日期" size="mini"
                            format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </div>
                </div>
                <div class="content_part Launch_box">
                    <span>投放时间</span>
                    <div class="duration">
                        <div class="during_box" @click="duration(index)" v-for="(item,index) in deli_time" :key="index"
                            :style="{'background':item.background,'borderColor':item.borderColor}">{{item.name}}</div>
                    </div>
                </div>
                <div class="content_part Internet_box" v-if="time_dev">
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <div class="Double_date">
                        <el-time-select v-model="start_time" :picker-options="{
                                        start: '00:00',
                                        step: '00:30',
                                        end: '23:59'
                                    }" placeholder="开始时间" size="mini">
                        </el-time-select>
                        <span>-</span>
                        <el-time-select v-model="end_time" :picker-options="{
                                        start: '00:00',
                                        step: '00:30',
                                        end: '23:59'
                                    }" placeholder="结束时间" size="mini">
                        </el-time-select>
                    </div>
                </div>
                <div class="content_part Launch_box">
                    <span>定价方式</span>
                    <div class="duration">
                        <div class="during_box" @click="handlePrice(index)" v-for="(item,index) in pricing" :key="index"
                            :style="{'background':item.background,'borderColor':item.borderColor}">{{item.name}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="basic_box">
            <h3>投放方向</h3>
            <div class="content_part">
                <span>区域</span>
                <div>
                    <el-select v-model="province_id" placeholder="省" size="mini" clearable style="width:132px"
                        @change="provinceFn" multiple collapse-tags>
                        <el-option v-for="item in province_arr" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                    <el-select v-model="city_id" placeholder="市" size="mini" clearable style="width:132px"
                        @change="cityFn" multiple collapse-tags>
                        <el-option v-for="item in city_arr" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                    <el-select v-model="area_id" placeholder="区" size="mini" clearable style="width:132px" multiple
                        collapse-tags>
                        <el-option v-for="item in area_arr" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div class="basic_box">
            <h3>广告创意</h3>
            <div class="content_part">
                <span>创意图片</span>
                <div style="display:flex;width:424px">
                    <el-input placeholder="请上传1张图片(大小:2000*250)" disabled size="mini"
                        style="width:310px;height:30px;margin-right:8px"></el-input>
                    <el-upload class="upload-demo" action="https://education.ztxinde.com/adver_upload"
                        :headers=myHeaders accept=".png,.gif,.jpg,.jpeg" name="image" :on-success="handleAvatarSuccess"
                        :show-file-list="false">
                        <el-button type="primary" size="small" style="width:100px;height:28px;line-height:0px">上传
                        </el-button>
                    </el-upload>
                </div>
            </div>
            <div class="content_part img_box" v-if="img_show">
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <img :src="img_url" alt="">
            </div>
            <div class="content_part ground_page">
                <span>落地页&nbsp;&nbsp;&nbsp;</span>
                <div class="To_born">
                    <div class="link_box" @click="handleGround(index)" v-for="(item,index) in landing" :key="index"
                        :style="{'background':item.background,'borderColor':item.borderColor}">{{item.name}}</div>
                </div>
            </div>
            <div class="content_part luo_input" v-if="link_show">
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <el-input v-model="ground" clearable placeholder="请输入落地页链接" size="mini"></el-input>
            </div>
        </div>
        <div class="btn">
            <el-button type="primary" @click="handleSubmit">提交</el-button>
            <el-button @click="handleRest">重置</el-button>
        </div>
        <!-- 提交弹框部分 -->
        <el-dialog :visible.sync="dialogVisible" width="571px" :before-close="handleClose">
            <div class="tipes">确认信息</div>
            <div class="message">
                <div class="basic_box">
                    <h1>基本信息</h1>
                    <span class="extension">推广项目名称</span>
                    <span class="situation">{{input_name}}</span>
                </div>
                <div class="basic_box">
                    <h1>广告形式</h1>
                    <span class="extension">广告展示形式</span>
                    <span class="situation">图片</span>
                </div>
                <div class="basic_box">
                    <h1>广告版位</h1>
                    <span class="extension">广告展示频道</span>
                    <span class="situation">{{ad_screet}}</span>
                </div>
                <div class="basic_box">
                    <h1>排期与出价</h1>
                    <div>
                        <span class="extension">投放日期类型</span>
                        <span class="situation" style="maegin-left:30px">{{ad_dateType}}</span>
                    </div>
                    <div style="margin-top:16px">
                        <span class="extension">投放日期</span>
                        <span class="situation" style="maegin-left:4px">{{ad_date}}</span>
                    </div>
                    <div style="margin-top:16px">
                        <span class="extension">投放时间</span>
                        <span class="situation" style="maegin-left:32px">{{ad_time}}</span>
                    </div>
                    <div style="margin-top:16px">
                        <span class="extension">定价方式</span>
                        <span class="situation" style="maegin-left:33px">{{price_type}}</span>
                    </div>
                </div>
                <div class="basic_box">
                    <h1>投放方向</h1>
                    <div style="display:flex">
                        <div class="extension">区域</div>
                        <div class="situation" style="maegin-left:62px">{{adress}}</div>
                    </div>
                </div>
            </div>
            <div class="pushs">
                <el-button class="refer" @click="dialogVisible=false">返回编辑</el-button>
                <el-button @click="handleSucess" type="primary">提交广告</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
let auth_token = sessionStorage.getItem("Token");
import { showPosterApi, cityPosterApi, areaPosterApi, positPosterApi, adverPosterApi, addPosterApi, editPosterApi, detialPosterApi } from '@/api'
export default {
    data () {
        return {
            // 获取token
            myHeaders: {
                'Token': auth_token
            },
            id: '',     //每条数据的id
            title: '',    //新建还是编辑
            // 登录用户id
            user_id: '',
            project_name: '',    //项目名称
            date_time: '',     //长期投放日期
            start_date: '',    //指定投放开始日期
            end_date: '',     //指定投放结束日期
            start_time: '',   //指定开始时间
            end_time: '',    //指定结束时间
            ground: '',     //落地页
            Single_long: true,   //投放日期显示隐藏
            Double_short: false,   //投放日期显示隐藏
            time_dev: false,      //指定时间的显示隐藏
            img_url: '',   //上传图片路径
            link_show: true,      //落地页链接显示隐藏
            img_show: false,     //图片的显示隐藏
            poster_list: [],    //广告展示位置
            ad_show: false,     //广告位置显示隐藏
            ad_son_show: true,    //广告具体图片显示隐藏
            ad_list: [],
            po_list: [],   //广告具体图片
            sel_data: [],  //广告位置
            wei_post: '',  //广告位置选中
            sel_show: false,   //广告位置下拉框显示
            province_arr: [],    //省份
            province_id: [],
            city_arr: [],      //市
            city_id: [],
            area_arr: [],    //区
            area_id: [],
            ad_imgId: '',    //广告具体选中图片位置
            date_type: '',   //投放时长类型
            time_type: '',    //投放时间类型
            price: '',    //定价方式
            lang_type: '',      //落地页类型
            // 投放时长
            delivery: [
                { "name": "长期投放", "background": "#ffffff", "borderColor": "#d4d4d4" },
                { "name": "指定日期", "background": "#ffffff", "borderColor": "#d4d4d4" }
            ],
            long_timeId: '',     //投放时长点击下标
            // 投放时间
            deli_time: [
                { "name": "全天", "background": "#ffffff", "borderColor": "#d4d4d4" },
                { "name": "指定时间", "background": "#ffffff", "borderColor": "#d4d4d4" }
            ],
            lang_time: '',     //投放时间点击下标
            // 定价方式
            pricing: [
                { "name": "点击计算", "background": "#ffffff", "borderColor": "#d4d4d4" },
                { "name": "刊位价格", "background": "#ffffff", "borderColor": "#d4d4d4" }
            ],
            priceId: '',     //定价方式点击下标
            // 落地页
            landing: [
                { "name": "自定义", "background": "#ffffff", "borderColor": "#d4d4d4" },
                { "name": "小程序", "background": "#ffffff", "borderColor": "#d4d4d4" },
                { "name": "电话号码", "background": "#ffffff", "borderColor": "#d4d4d4" },
                { "name": "无落地页", "background": "#ffffff", "borderColor": "#d4d4d4" }
            ],
            luodi: '',     //落地页点击下标
            // 提交确认页
            dialogVisible: false,
            input_name: '',
            ad_screet: '',
            ad_dateType: '',
            ad_date: '',
            ad_time: '',
            price_type: '',
            adress: '',
        }
    },
    created () {
        // this.initData()
        this.user_id = sessionStorage.getItem('id');
    },
    mounted () {
        this.id = this.$route.query.id;
        if (this.id == '新建广告') {
            this.title = '新建广告';
            this.initData();
        } else {
            this.title = '编辑广告';
            this.editData();
        }
    },
    methods: {
        // 初始化页面
        initData () {
            // this.user_id = sessionStorage.getItem('id');
            showPosterApi({
                type: 1,
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.province_arr = res.province;
                    this.poster_list = res.ad_source;
                    this.poster_list.map(item => {
                        this.$set(item, 'background', '#ffffff');
                        this.$set(item, 'borderColor', '#d4d4d4');
                    })
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 编辑展示数据
        editData () {
            showPosterApi({
                type: 2,
                auth_userid: this.user_id,  //登录用户id
                id: this.id,
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.province_arr = res.province;   //省份
                    this.city_arr = res.city;     //市区
                    this.area_arr = res.area;     //区域
                    this.poster_list = res.ad_source;    //广告展示频道
                    this.poster_list.map(item => {   //
                        this.$set(item, 'background', '#ffffff');
                        this.$set(item, 'borderColor', '#d4d4d4');
                    });
                    this.sel_data = res.ad_position;   //广告展示位置下拉框
                    this.ad_list = res.ad_img;  //广告具体展示位置
                    this.ad_list.map(item => {   //
                        this.$set(item, 'background', '#ffffff');
                        this.$set(item, 'borderColor', '#d4d4d4');
                        this.po_list = item.type;  //广告图片具体位置
                        this.po_list.map(items => {
                            this.$set(items, 'background', '#ffffff');
                            this.$set(items, 'borderColor', '#d4d4d4');
                            this.$set(item, 'width', '532px');
                        });
                    });
                    let arr = res.img_select;  //图片选中数组
                    let a = arr.ad_source.id;
                    this.wei_post = arr.ad_position.id;
                    this.ad_imgId = arr.ad_img.id;
                    // console.log(this.ad_imgId)
                    this.poster_list.map(item => {
                        if (a == item.id) {
                            item.background = '#EFFAFF';
                            item.borderColor = '#409eff';
                            this.sel_show = true;
                        } else {
                            item.background = '#ffffff';
                            item.borderColor = '#d4d4d4';
                            this.sel_show = false;
                        }
                    });
                    if (this.wei_post == '') {
                        this.ad_show = false;
                    } else {
                        this.ad_show = true;
                        if (this.po_list.length != 0) {
                            this.ad_son_show = false;
                            this.po_list.map(item => {
                                // console.log(item.id)
                                if (this.ad_imgId == item.uid) {
                                    item.background = '#EFFAFF';
                                    item.borderColor = '#409eff';
                                    let b = this.po_list.length;
                                    item.width = parseInt(531 / b) + 'px';
                                } else {
                                    item.background = '#ffffff';
                                    item.borderColor = '#d4d4d4';
                                    // item.width = '532px';
                                    let b = this.po_list.length;
                                    item.width = parseInt(531 / b) + 'px';
                                }
                            });
                        } else {
                            this.ad_son_show = true;
                        }
                    }
                    this.project_name = res.data.ad_name;    //项目名称
                    this.province_id = res.data.province_id.map((value) => { return Number(value); });  //省份选中
                    this.city_id = res.data.city_id.map((value) => { return Number(value); });    //市区选中
                    this.area_id = res.data.area_id.map((value) => { return Number(value); });    //区域选中
                    if (res.data.launch_time == 2) {   //投放时长
                        this.Double_short = true;
                        this.Single_long = false;
                        this.date_type = 2;
                        this.long_timeId = 1;
                        this.delivery.map((item, index) => {
                            if (this.long_timeId == index) {
                                item.background = '#EFFAFF';
                                item.borderColor = '#409eff';
                            } else {
                                item.background = '#ffffff';
                                item.borderColor = '#d4d4d4';
                            }
                        });
                        this.start_date = res.data.launch_time_start;
                        this.end_date = res.data.launch_time_end;
                    } else {
                        this.Double_short = true;
                        this.Single_long = true;
                        this.date_type = 1;
                        this.long_timeId = 0;
                        this.delivery.map((item, index) => {
                            if (this.long_timeId == index) {
                                item.background = '#EFFAFF';
                                item.borderColor = '#409eff';
                            } else {
                                item.background = '#ffffff';
                                item.borderColor = '#d4d4d4';
                            }
                        });
                        this.date_time = res.data.long_time_start;
                    };
                    if (res.data.launch_at == 2) {   //投放时间
                        this.time_dev = true;
                        this.start_time = res.data.launch_at_start.substring(0, 5);
                        this.end_time = res.data.launch_at_end.substring(0, 5);
                        this.time_type = 2;
                        this.lang_time = 1;
                        this.deli_time.map((item, index) => {
                            if (this.lang_time == index) {
                                item.background = '#EFFAFF';
                                item.borderColor = '#409eff';
                            } else {
                                item.background = '#ffffff';
                                item.borderColor = '#d4d4d4';
                            }
                        })
                    } else {
                        this.time_dev = false;
                        this.time_type = 1;
                        this.lang_time = 0;
                        this.deli_time.map((item, index) => {
                            if (this.lang_time == index) {
                                item.background = '#EFFAFF';
                                item.borderColor = '#409eff';
                            } else {
                                item.background = '#ffffff';
                                item.borderColor = '#d4d4d4';
                            }
                        })
                    };
                    if (res.data.price_method == 1) {    //定价方式
                        this.priceId = 0;
                        this.price = 1;
                        this.pricing.map((item, index) => {
                            if (this.priceId == index) {
                                item.background = '#EFFAFF';
                                item.borderColor = '#409eff';
                            } else {
                                item.background = '#ffffff';
                                item.borderColor = '#d4d4d4';
                            }
                        })
                    } else {
                        this.priceId = 1;
                        this.price = 2;
                        this.pricing.map((item, index) => {
                            if (this.priceId == index) {
                                item.background = '#EFFAFF';
                                item.borderColor = '#409eff';
                            } else {
                                item.background = '#ffffff';
                                item.borderColor = '#d4d4d4';
                            }
                        })
                    };
                    if (res.data.ad_image != '') {   //上传的图片
                        this.img_show = true;
                        this.img_url = res.data.ad_image;
                    } else {
                        this.img_show = false;
                        this.img_url = ''
                    };
                    if (res.data.land_page != 4) {  //落地页
                        this.lang_type = res.data.land_page;
                        this.link_show = true;
                        this.ground = res.data.land_url;
                        if (this.lang_type == 1) {
                            this.luodi = 0;
                            this.landing.map((item, index) => {
                                if (this.luodi == index) {
                                    item.background = '#EFFAFF';
                                    item.borderColor = '#409eff';
                                } else {
                                    item.background = '#ffffff';
                                    item.borderColor = '#d4d4d4';
                                }
                            })
                        } else if (this.lang_type == 2) {
                            this.luodi = 1;
                            this.landing.map((item, index) => {
                                if (this.luodi == index) {
                                    item.background = '#EFFAFF';
                                    item.borderColor = '#409eff';
                                } else {
                                    item.background = '#ffffff';
                                    item.borderColor = '#d4d4d4';
                                }
                            })
                        } else if (this.lang_type == 3) {
                            this.luodi = 2;
                            this.landing.map((item, index) => {
                                if (this.luodi == index) {
                                    item.background = '#EFFAFF';
                                    item.borderColor = '#409eff';
                                } else {
                                    item.background = '#ffffff';
                                    item.borderColor = '#d4d4d4';
                                }
                            })
                        }
                    } else {
                        this.lang_type = res.data.land_page;
                        this.link_show = true;
                        this.ground = res.data.land_url;
                        this.luodi = 3;
                        this.landing.map((item, index) => {
                            if (this.luodi == index) {
                                item.background = '#EFFAFF';
                                item.borderColor = '#409eff';
                            } else {
                                item.background = '#ffffff';
                                item.borderColor = '#d4d4d4';
                            }
                        })
                    }
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 返回按钮
        handleBack () {
            this.$router.push({ path: '/poster' })
        },
        // 点击广告展示频道
        handlePost (ad_id) {
            this.poster_list.map((item) => {
                if (ad_id == item.id) {
                    item.background = '#EFFAFF';
                    item.borderColor = '#409eff';
                    this.sel_show = true;
                    positPosterApi({
                        ad_source_id: ad_id,
                        auth_userid: this.user_id,  //登录用户id
                    }).then(res => {
                        // console.log(res)
                        if (res.status.code == 200) {
                            this.sel_data = res.data;
                        } else if (res.status.code == 201) {
                            this.$message.error(res.status.msg)
                        } else if (res.status.code == 202) {
                            this.$message.error('登录过期，请重新登录！');
                            this.$router.push({ path: '/login' })
                        }
                    })
                } else {
                    this.sel_show = false;
                    item.background = '#ffffff';
                    item.borderColor = '#d4d4d4';
                }
            })
        },
        // 点击广告分类下拉框
        position_Fn () {
            if (this.wei_post == '') {
                this.ad_show = false
            } else {
                this.ad_show = true;
                adverPosterApi({
                    ad_position_id: this.wei_post,
                    auth_userid: this.user_id,  //登录用户id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.ad_list = res.ad_img.ad_img;
                        this.ad_list.map(item => {
                            this.$set(item, 'background', '#ffffff');
                            this.$set(item, 'borderColor', '#d4d4d4');
                        })
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
        // 广告点击首页哪个位置
        handleAdver (name) {
            this.ad_list.map(item => {
                if (item.type.length != 0) {
                    this.ad_son_show = false;
                    this.po_list = item.type;
                    this.po_list.map(item => {
                        this.$set(item, 'background', '#ffffff');
                        this.$set(item, 'borderColor', '#d4d4d4');
                        this.$set(item, 'width', '532px');
                        if (name == item.name) {
                            let a = item.type.length;
                            item.width = parseInt(532 / a) + 'px';
                        }
                    });

                } else {
                    this.ad_son_show = true;
                }
            })
        },
        // 具体广告位置点击
        img_poition (id) {
            this.ad_imgId = id;
            this.po_list.map(item => {
                if (id == item.uid) {
                    item.background = '#EFFAFF';
                    item.borderColor = '#409eff';
                } else {
                    item.background = '#ffffff';
                    item.borderColor = '#d4d4d4';
                }
            })
        },
        // 点击投放时长
        Delivery_time (id) {
            this.long_timeId = id;
            this.delivery.map((item, index) => {
                if (id == index) {
                    item.background = '#EFFAFF';
                    item.borderColor = '#409eff';
                    if (index == 0) {
                        this.Single_long = true;
                        this.Double_short = true;
                        this.start_date = '';
                        this.end_date = '';
                        this.date_type = 1;
                    } else if (index == 1) {
                        this.Single_long = false;
                        this.Double_short = true;
                        this.date_time = '';
                        this.date_type = 2;
                    }
                } else {
                    item.background = '#ffffff';
                    item.borderColor = '#d4d4d4';
                }
            })
        },
        // 点击投放时间
        duration (id) {
            this.lang_time = id;
            this.deli_time.map((item, index) => {
                if (id == index) {
                    item.background = '#EFFAFF';
                    item.borderColor = '#409eff';
                    if (index == 0) {
                        this.time_dev = false;
                        this.start_time = '';
                        this.end_time = '';
                        this.time_type = 1;
                    } else if (index == 1) {
                        this.time_dev = true;
                        this.time_type = 2;
                    }
                } else {
                    item.background = '#ffffff';
                    item.borderColor = '#d4d4d4';
                }
            })
        },
        // 点击定价方式
        handlePrice (id) {
            this.priceId = id;
            this.pricing.map((item, index) => {
                if (id == index) {
                    item.background = '#EFFAFF';
                    item.borderColor = '#409eff';
                    if (index == 0) {
                        this.price = 1;
                    } else if (index == 1) {
                        this.price = 2;
                    }
                } else {
                    item.background = '#ffffff';
                    item.borderColor = '#d4d4d4';
                }
            })
        },
        // 图片上传
        handleAvatarSuccess (res) {
            // console.log(res)
            if (res.status.code == 200) {
                this.$message.success('图片上传成功！');
                this.img_url = res.status.img;
                this.img_show = true;
            } else {
                this.$message.error('图片上传失败！');
                this.img_show = false
            }
        },
        // 省份下拉框改变
        provinceFn () {
            if (this.province_id.length == 0) {
                this.city_arr = [];
                this.city_id = [];
                this.area_id = [];
                this.area_arr = [];
            } else {
                this.city_arr = [];
                this.city_id = [];
                this.area_id = [];
                this.area_arr = [];
                cityPosterApi({
                    province_id: this.province_id,
                    auth_userid: this.user_id,  //登录用户id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.city_arr = res.city;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
        // 市下拉框改变
        cityFn () {
            if (this.city_id.length == 0) {
                this.area_id = [];
                this.area_arr = [];
            } else {
                this.area_id = [];
                this.area_arr = [];
                areaPosterApi({
                    city_id: this.city_id,
                    auth_userid: this.user_id,  //登录用户id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.area_arr = res.area;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
        // 点击落地页
        handleGround (id) {
            this.luodi = id;
            this.landing.map((item, index) => {
                if (id == index) {
                    item.background = '#EFFAFF';
                    item.borderColor = '#409eff';
                    if (index == 0) {
                        this.link_show = true;
                        this.lang_type = 1;
                    } else if (index == 1) {
                        this.link_show = true;
                        this.lang_type = 2;
                    } else if (index == 2) {
                        this.link_show = true;
                        this.lang_type = 3;
                    } else if (index == 3) {
                        this.link_show = false;
                        this.lang_type = 4;
                    }
                } else {
                    item.background = '#ffffff';
                    item.borderColor = '#d4d4d4';
                }
            })
        },
        // 弹窗关闭
        handleClose () {
            this.dialogVisible = false;
        },
        // 点击提交按钮弹框出现
        handleSubmit () {
            this.dialogVisible = true;
            detialPosterApi({
                auth_userid: this.user_id,  //登录用户id
                ad_name: this.project_name,   //广告名称
                ad_type: 1,     //广告类型
                ad_img_id: this.ad_imgId,    //具体选中广告图片id
                launch_time: this.date_type,   //投放时长类型
                long_time_start: this.date_time,   //长期投放开始日期
                launch_time_start: this.start_date,   //指定投放日期开始时间
                launch_time_end: this.end_date,     //指定投放日期结束时间
                launch_at: this.time_type,    //投放时间类型
                launch_at_start: this.start_time,    //指定开始时间
                launch_at_end: this.end_time,     //指定结束时间
                price_method: this.price,    //定价方式
                ad_image: this.img_url,     //图片链接
                land_page: this.lang_type,      //落地页类型
                land_url: this.ground,    //落地页链接
                province_id: this.province_id,     //省份
                city_id: this.city_id,   //市区
                area_id: this.area_id,   //区域
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.input_name = res.data.ad_name;  //广告名称
                    this.ad_screet = res.data.ad_position;   //具体选中广告图片
                    this.ad_dateType = res.data.launch_time;    //投放时长类型
                    this.ad_date = res.data.launch_time_start;   //投放开始日期
                    this.ad_time = res.data.launch_at;    //投放时间
                    this.price_type = res.data.price_method;   //定价方式
                    this.adress = res.data.area;   //区域
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 点击取消按钮，清空所有
        handleRest () {
            this.project_name = '';    //项目名称输入框
            this.province_id = [];    //省份
            this.city_id = [];     //市
            this.area_id = [];     //区
            this.ground = '';     //落地页链接
            this.img_show = false;     //图片区域隐藏
            this.img_url = '';      //图片链 接
            this.date_time = '';    //长期投放日期
            this.start_date = '';    //指定投放开始日期
            this.end_date = '';     //指定投放结束日期
            this.start_time = '';    //指定时间开始
            this.end_time = '';      //指定时间结束
            this.wei_post = '';      //广告位置下拉框
            this.sel_show = false;   //广告位置下拉框隐藏
            this.ad_show = false;    //广告具体位置隐藏
            this.ad_son_show = true;   //广告具体图片隐藏
            this.Double_short = false;  //投放时间隐藏
            this.Single_long = true;    //指定日期隐藏
            this.time_dev = false;      //指定时间隐藏
            this.link_show = true;      //落地页链接显示
            this.poster_list.map(item => {  //广告展示频道清空选中
                item.background = '#ffffff';
                item.borderColor = '#d4d4d4';
            });
            this.ad_list.map(item => {   //广告具体位置清空选中
                item.background = '#ffffff';
                item.borderColor = '#d4d4d4';
            });
            this.po_list.map(item => {   //广告具体图片清空选中
                item.background = '#ffffff';
                item.borderColor = '#d4d4d4';
            });
            this.delivery.map(item => {   //投放时长清空选中
                item.background = '#ffffff';
                item.borderColor = '#d4d4d4';
            });
            this.deli_time.map(item => {  //投放时间清空选中
                item.background = '#ffffff';
                item.borderColor = '#d4d4d4';
            });
            this.pricing.map(item => {  //定价方式清空选中
                item.background = '#ffffff';
                item.borderColor = '#d4d4d4';
            });
            this.landing.map(item => {  //落地页清空选中
                item.background = '#ffffff';
                item.borderColor = '#d4d4d4';
            });
        },
        // 确认信息提交广告按钮
        handleSucess () {
            if (this.id == '新建广告') {
                addPosterApi({
                    auth_userid: this.user_id,  //登录用户id
                    ad_name: this.project_name,   //广告名称
                    ad_type: 1,     //广告类型
                    ad_img_id: this.ad_imgId,    //具体选中广告图片id
                    launch_time: this.date_type,   //投放时长类型
                    long_time_start: this.date_time,   //长期投放开始日期
                    launch_time_start: this.start_date,   //指定投放日期开始时间
                    launch_time_end: this.end_date,     //指定投放日期结束时间
                    launch_at: this.time_type,    //投放时间类型
                    launch_at_start: this.start_time,    //指定开始时间
                    launch_at_end: this.end_time,     //指定结束时间
                    price_method: this.price,    //定价方式
                    ad_image: this.img_url,     //图片链接
                    land_page: this.lang_type,      //落地页类型
                    land_url: this.ground,    //落地页链接
                    province_id: this.province_id,     //省份
                    city_id: this.city_id,   //市区
                    area_id: this.area_id,   //区域
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$router.push({ path: '/poster' });
                        this.$message.success('添加成功！');
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            } else {
                editPosterApi({
                    id: this.id,
                    auth_userid: this.user_id,  //登录用户id
                    ad_name: this.project_name,   //广告名称
                    ad_type: 1,     //广告类型
                    ad_img_id: this.ad_imgId,    //具体选中广告图片id
                    launch_time: this.date_type,   //投放时长类型
                    long_time_start: this.date_time,   //长期投放开始日期
                    launch_time_start: this.start_date,   //指定投放日期开始时间
                    launch_time_end: this.end_date,     //指定投放日期结束时间
                    launch_at: this.time_type,    //投放时间类型
                    launch_at_start: this.start_time,    //指定开始时间
                    launch_at_end: this.end_time,     //指定结束时间
                    price_method: this.price,    //定价方式
                    ad_image: this.img_url,     //图片链接
                    land_page: this.lang_type,      //落地页类型
                    land_url: this.ground,    //落地页链接
                    province_id: this.province_id,     //省份
                    city_id: this.city_id,   //市区
                    area_id: this.area_id,   //区域
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$router.push({ path: '/poster' });
                        this.$message.success('修改成功！');
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.poster_add {
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 12px;
    text-align: left;
    padding: 30px;
    //页头标题部分
    .Page_header {
        // 返回按钮部分
        .header_re {
            color: #333333;
            font-weight: 400;
            font-size: 14px;
            cursor: pointer;
        }
        // 页面名字部分
        .new_build {
            color: #333333;
            font-weight: normal;
            font-size: 30px;
            margin-top: 20px;
        }
    }
    // 内容显示部分 相同部分
    .basic_box {
        margin-top: 54px;
        h3 {
            margin-bottom: 40px;
            color: #333333;
            font-weight: normal;
            font-size: 20px;
            padding-left: 12px;
            border-left: 5px solid #409eff;
        }
        .content_part {
            display: flex;
            // align-items: center;
            span {
                color: #333333;
                font-weight: normal;
                font-size: 14px;
                padding-right: 54px;
            }
        }
        // 基本信息部分
        .project_name {
            .el-input {
                width: 188px;
                height: 30px;
            }
            ::v-deep.el-input__inner {
                width: 188px;
                height: 30px;
            }
        }
        // 广告形式部分
        .shape_box {
            .shap_img {
                width: 118px;
                height: 30px;
                border: 1px solid #409eff;
                background: #effaff;
                color: #333333;
                font-weight: normal;
                font-size: 14px;
                text-align: center;
                line-height: 30px;
            }
        }
        // 排期与出价 部分
        .Launch_box {
            .duration {
                display: flex;
                width: 272px;
                flex-wrap: wrap;
                margin-bottom: 24px;
                .during_box {
                    width: 134px;
                    height: 30px;
                    border: 1px solid;
                    color: #333333;
                    font-weight: normal;
                    font-size: 14px;
                    text-align: center;
                    line-height: 30px;
                }
            }
        }
        // 投放日期  时间
        .Internet_box {
            margin-bottom: 24px;
            .Single_date {
                .el-input {
                    width: 272px;
                    height: 30px;
                }
                ::v-deep.el-input__inner {
                    width: 272px;
                    height: 30px;
                }
            }
            .Double_date {
                span {
                    padding: 0 6px;
                }
                .el-input {
                    width: 128px;
                    height: 30px;
                }
                ::v-deep.el-input__inner {
                    width: 128px;
                    height: 30px;
                }
            }
        }
        // 落地页
        .ground_page {
            margin-top: 24px;
            .To_born {
                display: flex;
                width: 424px;
                .link_box {
                    width: 104px;
                    height: 30px;
                    border: 1px solid;
                    color: #333333;
                    font-weight: normal;
                    font-size: 14px;
                    text-align: center;
                    line-height: 30px;
                }
            }
        }
        // 落地页输入框
        .luo_input {
            margin-top: 12px;
            .el-input {
                width: 424px;
                height: 30px;
            }
            ::v-deep.el-input__inner {
                width: 424px;
                height: 30px;
            }
        }
        // 图片显示区域
        .img_box {
            width: 424px;
            height: 80px;
            margin-top: 12px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        // 广告展示频道
        .poster_wei {
            .advert_box {
                display: flex;
                flex-direction: column;
                .guan_weizhi {
                    width: 138px;
                    height: 30px;
                    border: 1px solid;
                    color: #333333;
                    font-weight: normal;
                    font-size: 14px;
                    text-align: center;
                    line-height: 30px;
                }
                .el-input {
                    width: 394px;
                    height: 30px;
                }
                ::v-deep.el-input__inner {
                    width: 394px;
                    height: 30px;
                }
            }
        }
    }
    // 广告展示频道
    .advertise {
        margin-top: 24px;
        .ad_wei {
            width: 532px;
            height: 94px;
            .ad_zhi {
                width: 532px;
                height: 94px;
                border: 1px solid;
                color: #333333;
                font-weight: normal;
                font-size: 14px;
                text-align: center;
                line-height: 94px;
            }
            .ad_post {
                display: flex;
                .ad_guan {
                    height: 94px;
                    border: 1px solid;
                    color: #333333;
                    font-weight: normal;
                    font-size: 14px;
                    text-align: center;
                    line-height: 94px;
                }
            }
        }
    }
    .btn {
        margin-top: 80px;
        .el-button {
            width: 118px;
            height: 38px;
            border-radius: 4px;
        }
    }
    // 弹框部分
    .el-dialog {
        text-align: left;
        position: relative;
        // 确认信息提示信息
        .tipes {
            width: 74px;
            color: #333333;
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            padding-bottom: 8px;
            border-bottom: 3px solid #409eff;
            position: absolute;
            left: 32px;
            top: 20px;
        }
        // 弹框主体部分
        .message {
            text-align: left;
            padding-left: 32px;
            .basic_box {
                margin-top: 42px;
                h1 {
                    color: #333333;
                    font-size: 16px;
                    font-weight: 400;
                    margin-bottom: 24px;
                }
                .extension {
                    color: #999999;
                    font-size: 14px;
                    font-weight: 400;
                    margin-right: 54px;
                }
                .situation {
                    color: #333333;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
        // 提交返回编辑按钮
        .pushs {
            text-align: right;
            padding-top: 30px;
            .el-button {
                width: 110px;
                height: 38px;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 600;
                color: #333333;
            }
            .refer {
                border: 1px solid #d4d4d4;
                margin-right: 8px;
                background: #ffffff;
            }
            // .call {
            //     background: #2479dd;
            //     color: #ffffff;
            //     border: none;
            // }
        }
    }
}
</style>